import { defineStore } from 'pinia'
import { RegistrationFormV2Response } from "~/grpc/proto/shackle/backend/v1/checkin_pb"
import type { Guest, FormError, ConfirmationData } from '~/types/checkIn'
import { useWebsiteStore } from '~/stores/website'
import { MP_FORM_EDITED, MP_CHECKIN_COMPLETED, MP_FORM_ERROR } from '~/plugins/mixpanel.client';

interface FormState {
  form: RegistrationFormV2Response;
  isInitialised: boolean;
  isComplete: boolean;
  isEditing: boolean;
  hasError: boolean;
  error?: FormError | null;
}

interface CheckInState {
  forms: { [guestId: string]: FormState };
}

export const useCheckInStore = defineStore('checkInStore', {
  persist: {
    paths: ['forms'],
    storage: persistedState.localStorage
  },
  state: (): CheckInState => ({
    forms: {}
  }),
  getters: {
    formByGuestId: (state) => (guestId: string) => {
      return state.forms[guestId]?.form
    },
    guestById: () => {
      const { guests } = useWebsiteStore()
      return (id: string): Guest | null => {
        const guest = guests.find((guest: Guest) => guest.guestId === id)
        if (!guest) return null
        return guest
      }
    },
    getFormStateByGuestId: (state) => (guestId: string) => {
      const formState = state.forms[guestId]
      return {
        isInitialised: formState?.isInitialised ?? false,
        isComplete: formState?.isComplete ?? false,
        isEditing: formState?.isEditing ?? false,
        hasError: formState?.hasError ?? false,
      }
    },
  },
  actions: {
    addForm(form: RegistrationFormV2Response) {
      if (!form.guestFormIdentifier?.guestId) return

      const guestId = form.guestFormIdentifier.guestId
      const guest = this.guestById(guestId)

      if (guest) {
        const { updateGuest } = useWebsiteStore()
        updateGuest({
          ...guest,
          status: 'INCOMPLETE'
        })
      }

      this.forms[guestId] = {
        form,
        isInitialised: true,
        isComplete: false,
        isEditing: false,
        hasError: false,
        error: null
      }
    },
    updateFormFlags(guestId: string, flags: Omit<FormState, 'form'>) {
      this.forms[guestId] = {
        ...this.forms[guestId],
        ...flags
      }
    },

    unEditForm(guest: Guest) {
      const guestId = guest.guestId
      if (this.forms[guestId]) {
        this.forms[guestId].isEditing = false
      }
    },

    editForm(guest: Guest) {
      const { updateGuest } = useWebsiteStore()
      const { $mixpanel } = useNuxtApp();
      const guestId = guest.guestId

      if (this.forms[guestId]) {
        this.forms[guestId].isComplete = false
        this.forms[guestId].isInitialised = false
        this.forms[guestId].isEditing = true
        $mixpanel(MP_FORM_EDITED, { guest })
      }

      updateGuest(guest)
    },

    markFormAsComplete(guestId: string, isSingleGuest?: boolean) {
      const guest = this.guestById(guestId)
      if (guest) {
        const { updateGuest } = useWebsiteStore()
        updateGuest({ ...guest, status: 'COMPLETED' })
      }

      if (!guest || !this.forms[guestId]) return

      this.forms[guestId].isEditing = false
      this.forms[guestId].isInitialised = false
      if (!isSingleGuest) {
        this.forms[guestId].isComplete = true
      }
    },

    async initializeRegistrationForm(guest: Guest): Promise<{ error: Error | null, data: RegistrationFormV2Response | null }> {
      const { reservation } = useWebsiteStore()
      if (!reservation) return { error: new Error('Tried to initialize form with no data'), data: null }

      const { data, error } = await useApiFetch('/check-in/initializeForm', {
        method: 'post',
        body: { guest, reservationName: reservation.name }
      })

      // check if data is nullish or undefined
      if (data === null || data === undefined || error !== null) {
        this.handleFormError(guest.guestId, error)
      } else {
        if (data.field) {
          this.addForm(data as RegistrationFormV2Response)
          if (data.field && data.field?.length === 0) {
            this.markFormAsComplete(data.guestFormIdentifier?.guestId)
          }
        }
      }

      return {
        error,
        data
      }
    },

    async submitForm(body: any): Promise<{ error: Error | null, data: RegistrationFormV2Response | null }> {
      const { data, error } = await useApiFetch('/check-in/submitForm', {
        method: 'post',
        body
      })

      if (data === null || data === undefined || error !== null) {
        this.handleFormError(body.guestFormIdentifier.guestId, error)
      } else {
        if (data.field && data.field?.length === 0) {
          this.markFormAsComplete(data.guestFormIdentifier?.guestId!)
        }
      }

      return {
        error,
        data
      }
    },

    async acceptTermsAndCondition(reservationName: string): Promise<any> {
      if (!reservationName) return
      return await useApiFetch('/check-in/acceptTermsAndConditions', {
        method: 'post',
        body: { reservationName }
      })
    },

    async checkIn(reservationName: string): Promise<any> {
      if (!reservationName) return

      const { guests, property } = useWebsiteStore()

      const { data, error } = await useApiFetch('/check-in/complete', {
        method: 'post',
        body: { reservationName }
      })

      if (error) throw error

      if (data) {
        const confirmationData: ConfirmationData = {
          name: reservationName,
          propertyName: property.name,
          frontOfficeId: property.frontOfficeId,
          property,
          guests
        }

        localStorage.setItem(`shackle-confirmation`, JSON.stringify(confirmationData))
        const { $mixpanel } = useNuxtApp();
        $mixpanel(MP_CHECKIN_COMPLETED, { confirmationData })
        return data
      }
    },

    handleFormError(guestId: string, error: Error | null) {
      if (this.forms[guestId]) {
        if (error) {
          const { $mixpanel } = useNuxtApp();
          this.forms[guestId].hasError = true
          this.forms[guestId].error = error
          $mixpanel(MP_FORM_ERROR, { guestId, error })
          throw error
        }
      }
    },
  }
})
